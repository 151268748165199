/* ------------------------------------------------------------
    STYLES
    
    1 ::::: BASE 
    Variables
    Mixins
    Reset
    Base

    2 ::::: LIB
    Forms
    Buttons
    Icons
    Tables

    3 ::::: PLUGINS
    Slick 1.8.1

    4 ::::: RESPONSIVE
    Largedevices
    Mediumdevices
    Smalldevices

------------------------------------------------------------ */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyXsovBJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyovBJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyVVpfBJ.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v222/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOem.ttf) format('truetype');
}
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
/* ------------------------------------------------------------
    FONTS
------------------------------------------------------------ */
/* ------------------------------------------------------------
    COLORS
------------------------------------------------------------ */
.text-white {
  color: #fff;
}
.text-black {
  color: #000;
}
.bg-black {
  background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
}
/* ------------------------------------------------------------
    ANIMATIONS
------------------------------------------------------------ */
a,
input,
button {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@keyframes fadeInUp {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}
/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */
html,
body {
  -webkit-text-size-adjust: none;
  /* For iphone Landscape */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  /* For font looks antialiased */
  -moz-osx-font-smoothing: grayscale;
  /* Moz antialiased */
  text-rendering: optimizeLegibility;
  /* optimezy fonts */
  font-size: 16px;
  line-height: 25px;
  color: #000;
  font-family: 'Roboto Condensed', sans-serif;
}
h1 {
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
}
h1 span {
  font-size: 34px;
  line-height: 70px;
  background: #000;
  /*display: inline-block;*/
  padding: 7px 9px;
  white-space: pre-wrap;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
h2 {
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.6px;
  margin-bottom: 0;
}
h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #000;
  text-transform: uppercase;
}
h3 span {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0px;
  color: #000000;
  display: block;
}
p,
li {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}
a.link-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000;
  border-bottom: 3px solid transparent;
  text-decoration: none;
}
a.link-text:hover {
  border-bottom: 3px solid #000;
}
a.link-text.text-white {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000;
  border-bottom: 3px solid transparent;
  text-decoration: none;
}
a.link-text.text-white:hover {
  border-bottom: 3px solid #fff;
}
a.link-text.text-white.active {
  border-bottom: 3px solid #fff;
}
a.btn-cta {
  background: #000000;
  border: 1px solid #FFFFFF;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: #fff;
  text-transform: uppercase;
  padding: 9px 31px 9px 11px;
  text-decoration: none;
  position: relative;
}
a.btn-cta i {
  transform: rotate(336deg);
  position: absolute;
  right: 5px;
  top: 10px;
  font-size: 18px;
}
a.btn-cta:hover {
  background: linear-gradient(180deg, #000000 0%, #2D2D2D 100%);
}
button.btn-action {
  background: #000000;
  border: 1px solid #FFFFFF;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: #fff;
  text-transform: uppercase;
  padding: 9px 58px;
  text-decoration: none;
  text-align: center;
}
button.btn-action:hover {
  background: linear-gradient(180deg, #000000 0%, #2D2D2D 100%);
}
.label-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.6px;
  color: #000;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  display: block;
  width: 100%;
}
.input-form {
  border: 1px solid #000000;
  padding: 8px 5px 3px 10px;
  width: 100%;
  font-size: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.input-form::-webkit-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input-form::placeholder {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  opacity: 0.5;
  text-transform: capitalize;
  font-size: 22px;
  line-height: 26px;
  color: #0000006C;
}
.input-form:focus {
  outline: none;
}
.input-form:active {
  outline: none;
}
select.select-form {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 100%;
  border: 1px solid #000;
  padding: 8px 10px 3px 10px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  opacity: 0.5;
  font-size: 22px;
  line-height: 26px;
  color: #0000006C;
  position: relative;
  background-image: url('../images/icons/expand_more_FILL0_wght400_GRAD0_opsz48.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
}
select.select-form:focus {
  color: #000;
  font-size: 22px;
  outline: none;
}
select.select-form:active {
  color: green;
  font-size: 12px;
  outline: none;
}
.textarea-form {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  width: 100%;
  height: 150px;
  border: 1px solid #000000;
  padding: 8px 10px;
  color: #0000006C;
  opacity: 0.5;
  font-size: 18px;
  line-height: 22px;
  outline: none;
}
.textarea-form:focus {
  opacity: 1;
  color: #000;
  font-size: 18px;
  outline: none;
}
.label-checkout {
  font-size: 18px;
  color: #000;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  display: block;
  margin-right: 10px;
}
.checkbox-form {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #000;
  width: 16px;
  height: 16px;
}
.checkbox-form:checked {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  background-image: url('../images/icons/done_FILL0_wght400_GRAD0_opsz48.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px;
}
.label-radio {
  font-size: 18px;
  color: #000;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  display: block;
  margin-right: 10px;
}
.radio-form {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #000;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.radio-form:checked {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 16px;
  height: 16px;
  border: 5px solid #000;
}
#header {
  width: 100%;
  height: 450px;
  position: relative;
}
#header figure {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -9;
}
#header figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#header .wrap-nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
}
#header .wrap-nav .bg-nav {
  width: 100%;
  height: 90px;
  background: rgba(0, 0, 0, 0.41);
}
#header .logo-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 100px;
}
#header .logo-wrap .logotipo-header {
  width: 220px;
  filter: invert(1);
  margin-left: 50px;
}
#footer {
  background-color: #000;
}
#footer .logotipo-footer {
  width: 150px;
  filter: invert(1);
}
#footer .social-anchors {
  display: flex;
  justify-content: end;
  align-items: center;
}
#footer .social-anchors a {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: 15px;
}
#footer .social-anchors a img {
  width: 100%;
  height: 100%;
}
#hero-image {
  width: 100%;
  height: 650px;
  position: relative;
}
#hero-image figure {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -9;
}
#hero-image figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#services-card {
  background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
  padding: 50px 0px;
}
#services-card .card-serv {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 210px;
  transition: all ease 0.5s;
  cursor: pointer;
}
#services-card .card-serv.active {
  padding-top: 210px;
}
#services-card .card-serv.active .card-text {
  display: block;
}
#services-card .card-serv figure {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
#services-card .card-serv figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#services-card .card-serv .card-heading {
  z-index: 999;
  position: relative;
  background: #fff;
}
#services-card .card-serv .card-text {
  display: none;
}
#services-card .card-serv .card-text ul {
  list-style: "//";
}
#services-card .card-serv .card-text ul li {
  padding-left: 20px;
}
#services-card .card-serv .card-text a {
  text-align: center;
  float: right;
  margin-bottom: 20px;
  width: 32px;
  height: 32px;
  background: #000;
  border-radius: 50%;
  line-height: 22px;
}
#services-card .card-serv .card-text a i {
  margin-top: 4px;
  color: #fff;
}
#clients-cards .card-client {
  width: 100%;
  min-height: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all ease 0.5s;
  padding: 0;
}
#clients-cards .card-client figure {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
#clients-cards .card-client figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#clients-cards .card-client .card-heading {
  z-index: 999;
  position: relative;
  background: #fff;
}
#clients-cards .card-client:hover .cover-info {
  display: block;
}
#clients-cards .card-client .cover-info {
  position: absolute;
  background-color: #fff;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: content-box;
  padding: 20px;
  display: none;
  transition: all ease 0.5s;
  animation: fadeInUp 0.5s ease backwards;
  cursor: pointer;
}
#clients-cards .card-client .cover-info img {
  margin-bottom: 20px;
}
#clients-cards .card-client .cover-info p {
  font-size: 16px;
}
#clients {
  background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
}
#proceso .steps img {
  display: block;
  margin: 0 auto 30px auto;
}
#proceso .steps h3 {
  height: 60px;
  text-align: center;
}
#proceso .steps p {
  font-size: 14px;
}
#contacto {
  width: 100%;
  height: 650px;
  position: relative;
}
#contacto figure {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -9;
}
#contacto figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.btn-tabs {
  background: #000;
  padding: 10px 30px;
  text-decoration: none;
  margin-right: 20px;
  transition: all ease-out 1s;
  line-height: 50px;
}
.btn-tabs i {
  line-height: 20px;
  font-size: 32px;
  top: 11px;
  position: relative;
  color: #fff;
}
.btn-tabs span {
  display: none;
}
.btn-tabs:hover {
  background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
  transition: all ease-out 1s;
}
.btn-tabs:hover i {
  margin-right: 30px;
}
.btn-tabs:hover span {
  display: contents;
  line-height: 40px;
  color: #fff;
  text-decoration: none;
}
#benefits .active .btn-tabs {
  background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
}
#benefits .active .btn-tabs i {
  margin-right: 30px;
}
#benefits .active .btn-tabs span {
  display: contents;
  line-height: 40px;
  color: #fff;
  text-decoration: none;
}
.text-white p {
  color: #fff;
}
@media (max-width: 1200px) {
  #clients-cards .card-client {
    min-height: 350px !important;
  }
}
@media (max-width: 992px) {
  #proceso .steps p {
    text-align: center;
  }
}
@media (max-width: 575px) {
  #footer .logotipo-footer {
    display: block;
    margin: 0 auto;
  }
  #footer .social-anchors {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
