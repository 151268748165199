/* ------------------------------------------------------------
    ANIMATIONS
------------------------------------------------------------ */

.transitionEase ( @prop, @speed, @type ) {
    -webkit-transition: @prop @speed @type;
       -moz-transition: @prop @speed @type;
        -ms-transition: @prop @speed @type;
         -o-transition: @prop @speed @type;
            transition: @prop @speed @type;
}

// All transitions
a,
input,
button {
  .transitionEase( all, .3s, ease);
}


@keyframes fadeInUp{
  0%{transform:translate(0px, 0px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}