/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */

html,
body {
  -webkit-text-size-adjust: none;
  /* For iphone Landscape */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  /* For font looks antialiased */
  -moz-osx-font-smoothing: grayscale;
  /* Moz antialiased */
  text-rendering: optimizeLegibility;
  /* optimezy fonts */
  font-size: 16px;
  line-height:25px;
  color: @c-black;
  font-family: @f-Regular;
  //height: 100%;
}

h1{
    color: @c-white;
    .font(@f-Regular; 700; normal);
    span{
        font-size: @fs-h1;
        line-height: 70px;
        background: @c-black;
        /*display: inline-block;*/
        padding: 7px 9px;
        white-space: pre-wrap;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }
}
h2{
    text-transform: uppercase;
    .font(@f-Regular; 700; normal);
    color: @c-black;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.6px;
    margin-bottom: 0;
}
h3{
    .font(@f-Regular; 700; normal);
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0px;
    color: @c-black;
    text-transform: uppercase;
    span{
        .font(@f-Regular; 300; normal);
        letter-spacing: 0px;
        color: #000000;
        display: block;
    }
}
p, li{
    .font(@f-Regular; 400; normal);
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 24px;
    color: @c-black;
}
a.link-text{
    .font(@f-Regular; 700; normal);
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color:@c-black;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    &:hover{
        border-bottom: 3px solid @c-black;
    }
}
a.link-text.text-white{
    .font(@f-Regular; 700; normal);
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color:@c-black;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    &:hover{
        border-bottom: 3px solid @c-white;
    }
    &.active{
        border-bottom: 3px solid @c-white;
    }
}
a.btn-cta{
    background: #000000;
    border: 1px solid #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    .font(@f-Regular; 400; normal);
    letter-spacing: 0px;
    color: @c-white;
    text-transform: uppercase;
    padding: 9px 31px 9px 11px;
    text-decoration: none;
    position: relative;
    i{
        transform: rotate(336deg);
        position: absolute;
        right: 5px;
        top: 10px;
        font-size: 18px;
    }
    &:hover{
        background: linear-gradient(180deg, #000000 0%, #2D2D2D 100%);
    }
}

button.btn-action{
    background: #000000;
    border: 1px solid #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    .font(@f-Regular; 400; normal);
    letter-spacing: 0px;
    color: @c-white;
    text-transform: uppercase;
    padding: 9px 58px;
    text-decoration: none;
    text-align: center;
    &:hover{
        background: linear-gradient(180deg, #000000 0%, #2D2D2D 100%);
    }
}

.label-text{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.6px;
    color: @c-black;
    text-transform: uppercase;
    .font(@f-Regular; 700; normal);
    display: block;
    width: 100%;
}

.input-form{
    &::-webkit-appearance{
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    }
    &::placeholder {
        .font(@f-Regular; 300; normal);
        opacity: .5;
        text-transform: capitalize;
        font-size: 22px;
        line-height: 26px;
        color: @c-gray;
    }
    border: 1px solid #000000;
    padding: 8px 5px 3px 10px;
    width: 100%;
    font-size: 18px;
    .font(@f-Regular; 400; normal);
    &:focus{
        outline: none;
    }
    &:active{
        outline: none;
    }
}

select.select-form{
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    width: 100%;
    border: 1px solid @c-black;
    padding: 8px 10px 3px 10px;
    .font(@f-Regular; 300; normal);
    opacity: .5;
    font-size: 22px;
    line-height: 26px;
    color: @c-gray;
    position: relative;
    background-image: url('../../images/icons/expand_more_FILL0_wght400_GRAD0_opsz48.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px;
   &:focus{
    color: @c-black;
    font-size: 22px;
    outline: none;
   }
   &:active{
    color: green;
    font-size: 12px;
    outline: none;
   }
}

.textarea-form{
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    width: 100%;
    height: 150px;
    border: 1px solid #000000;
    padding: 8px 10px;
    color: @c-gray;
    opacity: .5;
    font-size: 18px;
    line-height: 22px;
    outline: none;
    &:focus{
        opacity: 1;
        color: @c-black;
        font-size: 18px;
        outline: none;
    }
}

.label-checkout{
    font-size: 18px;
    color: @c-black;
    .font(@f-Regular; 400; normal);
    display: block;
    margin-right: 10px;
}
.checkbox-form{
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    border: 1px solid @c-black;
    width: 16px;
    height: 16px;
    &:checked{
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
        background-color: @c-white;
        background-image: url('../../images/icons/done_FILL0_wght400_GRAD0_opsz48.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 18px;
    }
}
.label-radio{
    font-size: 18px;
    color: @c-black;
    .font(@f-Regular; 400; normal);
    display: block;
    margin-right: 10px;
}
.radio-form{
    -webkit-appearance:     none;
    -moz-appearance:        none;
    -ms-appearance:         none;
    -o-appearance:          none;
    appearance:             none;
    border: 1px solid @c-black;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    &:checked{
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
        width: 16px;
        height: 16px;
        border: 5px solid @c-black;
    }
}
#header{
    width: 100%;
    height: 450px;
    position: relative;
    figure{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -9;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .wrap-nav{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: end;
        .bg-nav{
            width: 100%;
            height: 90px;
            background: rgba(0,0,0,.41);
        }
    }
    .logo-wrap{
        position: absolute;
        top: 0;
        width: 100%;
        padding-top: 100px;
        .logotipo-header{
            width: 220px;
            filter: invert(1);
            margin-left: 50px;
        }
    }
}

#footer{
    background-color: @c-black;
    .logotipo-footer{
        width: 150px;
        filter: invert(1);
    }
    .social-anchors{
        display: flex;
        justify-content: end;
        align-items: center;
        a{
            width: 22px;
            height: 22px;
            display: inline-block;
            margin-left: 15px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

#hero-image{
    width: 100%;
    height: 650px;
    position: relative;
    figure{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -9;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

#services-card{
    background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
    padding: 50px 0px;
    .card-serv{
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 210px;
        transition: all ease 0.5s;
        cursor: pointer;
        &.active{
            padding-top: 210px;
            .card-text{
                display: block;
            }
        }
        figure{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .card-heading{
            z-index: 999;
            position: relative;
            background: @c-white;
        }
        .card-text{
            display: none;
            ul{
                list-style: "//";
                li{
                    padding-left: 20px;
                }
            }
            a{
                text-align: center;
                float: right;
                margin-bottom: 20px;
                width: 32px;
                height: 32px;
                background: @c-black;
                border-radius: 50%;
                line-height: 22px;
                i{
                    margin-top: 4px;
                    color: @c-white;
                }
            }
        }
    }
}

#clients-cards{
    .card-client{
        width: 100%;
        min-height: 250px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: all ease 0.5s;
        padding: 0;
        figure{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .card-heading{
            z-index: 999;
            position: relative;
            background: @c-white;
        }
        &:hover{
            .cover-info{
                display: block;
            }
        }
        .cover-info{
            position: absolute;
            background-color: @c-white;
            z-index: 999;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: content-box;
            padding: 20px;
            display: none;
            transition: all ease 0.5s;
            animation:fadeInUp 0.5s ease backwards;
            cursor: pointer;
            img{
                margin-bottom: 20px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

#clients{
    background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
}
#proceso{
    .steps{
        img{
            display: block;
            margin: 0 auto 30px auto;
        }
        h3{
            height: 60px;
            text-align: center;
        }
        p{
            font-size: 14px;
        }
    }
}

#contacto{
    width: 100%;
    height: 650px;
    position: relative;
    figure{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -9;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
    }   
}

.btn-tabs{
    background: @c-black;
    padding: 10px 30px;
    text-decoration: none;
    margin-right: 20px;
    transition: all ease-out 1s;
    line-height: 50px;
    i{
        line-height: 20px;
        font-size: 32px;
        top: 11px;
        position: relative;
        color: #fff;
    }
    span{
        display: none;
    }
    &:hover{
        background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
        i{
            margin-right: 30px;
        }
        span{
            display: contents;
            line-height: 40px;
            color: @c-white;
            text-decoration: none;
        }
        transition: all ease-out 1s;
    }
}
#benefits{
    .active{
        .btn-tabs{
            background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
            i{
                margin-right: 30px;
            }
            span{
                display: contents;
                line-height: 40px;
                color: @c-white;
                text-decoration: none;
            }
        }
    }
}

.text-white{
    p{
        color: @c-white;
    }
}