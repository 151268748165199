@media (max-width: 1200px){
    #clients-cards .card-client {
        min-height: 350px !important;
    }
}

@media (max-width: 992px){
    #proceso {
        .steps{ 
            p{
                text-align: center;
            }
        }
    }
}
@media (max-width: 575px){
    #footer{
        .logotipo-footer{
            display: block;
            margin: 0 auto;
        }
        .social-anchors{
            padding-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}