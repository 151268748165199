@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@400');

/* ------------------------------------------------------------
    FONTS
------------------------------------------------------------ */
@f-Regular: 'Roboto Condensed', sans-serif;
.font(@fontFamily: @f-Regular; @fontWeight: normal; @fontStyle: normal) {
    font-family: @fontFamily;
    font-style: @fontStyle;
    font-weight: @fontWeight;
}
@fs-h1: 34px;

/* ------------------------------------------------------------
    COLORS
------------------------------------------------------------ */
@c-black: #000;
@c-white: #fff;
@c-gray: #0000006C;


.text-white{
    color: @c-white;
}
.text-black{
    color: @c-black;
}
.bg-black{
    background: transparent linear-gradient(0deg, #000000 0%, #2D2D2D 100%) 0% 0% no-repeat padding-box;
}